import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery, navigate } from 'gatsby';
import styled from 'styled-components';
import { Card, Image, Layout, Metadata, NewsRoll, CategoryRoll } from '../components';
import { media, color } from '../theme';
import { vr, useDanishMonth, useConfig } from '../utils';

const Article = styled(Link)`
  margin: 0 0 ${vr(1)} 0;
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;

  .image {
    width: 100%;
    display: flex;
    height: auto;

    img {
      width: 100%;
    }
  }

  .content {
    padding: ${vr(0.75)} 0 ${vr(1.5)};
  }

  &:hover h2 {
    color: ${color.gold};
  }

  @media ${media.tabletL} {
    display: flex;
    flex-direction: row;

    .content {
      padding: ${vr(0.5)} ${vr(2.5)} ${vr(0.5)} ${vr(1)};
    }

    .image {
      max-width: 350px;
    }
  }
`;

const NewsPage = ({ data, pageContext }) => {
  const { month, year } = pageContext;
  const { edges: posts } = data.allMdx;
  const page = data.mdx.frontmatter;

  const { startMonth, startYear } = useConfig();

  const today = new Date();

  const thisMonth = today.getMonth();
  const thisYear = today.getFullYear();

  function displayMonths() {
    const list = [];

    list.push(<option value="/nyheder/">Nyeste</option>);

    for (let y = thisYear; y >= startYear; y -= 1) {

      // If starting year then start from selected month, otherwise start from Janaury
      const startMonthFrom = y === startYear ? startMonth : 0;
      const endMonthAt = y === startYear ? thisMonth : 11;

      // list.push(<option disabled>{y}</option>);

      for (let m = endMonthAt; m >= startMonthFrom; m -= 1) {
        const mo = (`0${m + 1}`).slice(-2);
        list.push(<option value={`/nyheder/${y}/${mo}/`} selected={m === month}>{useDanishMonth(m)} {y}</option>);
      }

    }

    return list;
  }

  const { metadata } = page;
  const title = metadata?.title.length > 1 ? metadata.title : page.title;
  const description = metadata?.description.length > 1 ? metadata.description : '';

  return (
    <Layout>
      <Metadata title={title} description={description} image={page.image} />
      <div className="full-width-image slim" id="hero-image">
        <Image src={page.image} position="center 20%" />
        <div className="container">
          <h1>{page.title}</h1>
        </div>
      </div>
      <section className="blogroll">
        <NewsRoll height="auto" />
      </section>
      <section style={{ background: color.darkwhite }}>
        <CategoryRoll />
      </section>
      {/* <section>
        <div className="container">
          <h1>{month ? `Nyheder fra ${useDanishMonth(month)} ${year}` : 'Nyheder'}</h1>
          <label htmlFor="selectMonth" className="inline">
            <span>Vis:</span>
            <select onChange={(e) => navigate(e.target.value)} id="selectMonth">
              {displayMonths()}
            </select>
          </label>
          <div style={{ maxWidth: '100%', marginTop: vr(1) }}>
            {posts.map((p) => {
              const post = p.node.frontmatter;

              return (
                <Article key={p.node.id} to={p.node.fields.slug}>
                  <div className="image" data-overlay="half">
                    <Image src={post.image} />
                  </div>
                  <div className="content">
                    <h2>{post.title}</h2>
                    <small>{post.date}</small>
                    <p>{p.node.excerpt}</p>
                  </div>
                </Article>
              );
            })}
          </div>
        </div>
      </section> */}
    </Layout>
  );
};

export default NewsPage;

export const newsPageQuery = graphql`
  query NewsPageQuery($limit: Int!) {
    allMdx(limit: $limit, filter: {frontmatter: {templateKey: {eq: "news-post"}, hideFromPageGeneration: {ne: true}}}, sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          fields {
            slug
          }
          excerpt
          frontmatter {
            date(formatString: "Do MMMM YYYY", locale: "da")
            title
            image {
              childImageSharp {
                resize(width: 350, height: 180) {
                  aspectRatio
                  src
                }
              }
            }
          }
        }
      }
    }
    mdx(frontmatter: {templateKey: {eq: "news-page"}}) {
      frontmatter {
        title
        metadata {
          title
          description
        }
        image {
          childImageSharp {
            fluid(
              maxWidth: 1024,
              quality: 70,
              duotone: {
                highlight: "#cc3232",
                shadow: "#51133d"
              }
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
